<template>
  <div style="background-color: #f4f4f4">
    <a-row id="jdal">
      <a-col :span="18" offset="3">
        <a-row>
          <a-breadcrumb>
            <a-breadcrumb-item>
              <router-link :to="{ name: '首页' }" v-scroll-to="'#top'">
                <a-icon type="home" />
                <span>首页</span>
              </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item> 经典案例 </a-breadcrumb-item>
          </a-breadcrumb>
        </a-row>
      </a-col>
    </a-row>
    <a-row style="margin-top: 50px; margin-bottom: 30px">
      <a-col :span="10" offset="6">
        <casesList
          interfaceUrl="/api/GetArticlesInfoController?id="
          :categoryId="categoryId"
          pageSize="10"
          :key="refresh"
        ></casesList>
      </a-col>
      <a-col :span="8">
        <a-affix :offset-top="160">
          <div
            style="
              height: 10px;
              border-top: 1px rgba(0, 0, 0, 0.3) solid;
              width: 80%;
            "
          ></div>
          <div>
            <a-card :bordered="false" style="background-color: unset">
              <div>
                <!-- <img
                  slot="cover"
                  style="width: 326px; height: 170px"
                  alt="example"
                  :src="item2.pic"
                /> -->
              </div>
              <a-card-meta>
                <template slot="title">
                  <div
                    style="
                      width: 100%;
                      font-size: 22px;
                      color: #bca474;
                      font-weight: 500;
                      float: left;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    类别
                  </div>
                </template>
                <template slot="description">
                  <div
                    style="
                      font-weight: 500;
                      text-align: left;
                      margin-top: 5px;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 2;
                      overflow: hidden;
                    "
                  >
                    <a-row>
                      <a-col :span="24" style="font-size: 18px">
                        <p
                          v-for="(item, index) in classicCasesCategory"
                          :key="index"
                        >
                          <span style="color: #bca474; font-weight: bolder">
                            ·
                          </span>
                          <a
                            style="color: rgb(54, 54, 54)"
                            @click="getList(item.id)"
                          >
                            {{ item.categoryName }}
                          </a>
                        </p>
                        <!-- <p>
                          <span style="color: #bca474; font-weight: bolder">
                            ·
                          </span>
                          <router-link to="/" style="color: rgb(54, 54, 54)">
                            代理公司事务
                          </router-link>
                        </p>
                        <p>
                          <span style="color: #bca474; font-weight: bolder">
                            ·
                          </span>
                          <router-link to="/" style="color: rgb(54, 54, 54)">
                            合同纠纷
                          </router-link>
                        </p>
                        <p>
                          <span style="color: #bca474; font-weight: bolder">
                            ·
                          </span>
                          <router-link to="/" style="color: rgb(54, 54, 54)">
                            婚姻家庭纠纷
                          </router-link>
                        </p>
                        <p>
                          <span style="color: #bca474; font-weight: bolder">
                            ·
                          </span>
                          <router-link to="/" style="color: rgb(54, 54, 54)">
                            房地产纠纷
                          </router-link>
                        </p>
                        <p>
                          <span style="color: #bca474; font-weight: bolder">
                            ·
                          </span>
                          <router-link to="/" style="color: rgb(54, 54, 54)">
                            法律顾问
                          </router-link>
                        </p> -->
                      </a-col>
                    </a-row>
                  </div>
                </template>
              </a-card-meta>
            </a-card>
          </div>
        </a-affix>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import casesList from "../components/casesList.vue";
import request from "@/utils/request";

export default {
  components: { casesList },
  data() {
    return {
      classicCasesCategory: [],
      categoryId: "",
      refresh: 0,
    };
  },
  methods: {
    getList(id) {
      console.log(id);
      this.categoryId = id;
      this.refresh++;
    },
  },
  computed: {
    // 团队照片
    listTemp2: function () {
      var list = this.ourTeam; //当前要拆分的数组
      var arrTemp = [];
      var index = 0;
      var sectionCount = 5; //单页或者单行数量
      // 限制6个元素
      for (var i = 0; i < list.length; i++) {
        index = parseInt(i / sectionCount);
        if (arrTemp.length <= index) {
          arrTemp.push([]);
        }
        arrTemp[index].push(list[i]);
      }
      return arrTemp; //分页数组
    },
  },
  mounted() {
    let that = this;
    // 获取经典案例下的分类
    request({
      url: "/api/GetCategoryController",
      method: "GET",
      params: {
        id: 2,
      },
    }).then((res) => {
      console.log("res= ", res);
      that.classicCasesCategory = res.data;
    });
    this.categoryId = 3;
    this.refresh++;
  },
};
</script>