<template>
  <div style="background-color: #f4f4f4">
    <a-row id="gywm">
      <a-col :span="18" offset="3">
        <a-row>
          <a-breadcrumb>
            <a-breadcrumb-item>
              <router-link :to="{ name: '首页' }" v-scroll-to="'#top'">
                <a-icon type="home" />
                <span>首页</span>
              </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item> 关于我们 </a-breadcrumb-item>
          </a-breadcrumb>
        </a-row>
      </a-col>
    </a-row>
    <a-row style="margin-top: 50px; margin-bottom: 30px">
      <a-col :span="18" offset="3">
        <a-row style="margin-top: 30px; margin-bottom: 30px">
          <a-col :span="5">
            <div style="margin: 1rem 0">明桂律师事务所</div>
          </a-col>
          <a-col :span="18" offset="1">
            <div style="color: #363636">
              <div style="font-size: 20px; font-weight: 500; margin: 1rem 0">
                <a-row>
                  <a-col :span="22" offset="1" v-html="intro.richText">
                    <!-- 广西明桂律师事务所成立于一九九四年，直属自治区司法
                    厅监督指导。本所现有合伙人27名，注册的执业律师69
                    名，拥有3000多㎡的高档写字楼，配备了专业管理软件
                    和法律软件系统。依托现代化的办公条件和先进的通讯设
                    施、计算机与互联网技术，构筑了与客户及时沟通和快捷
                    服务的平台。
                    <p />
                    本所大部分合伙人和执业律师毕业于国内北京大学、中国
                    政法大学、西南政法大学等知名法学院校，曾工作于人大
                    、政府、政协、高校、银行、公安、法院、检察院、司法
                    行政等部门，在相关专业领域工作多年且已取得良好的业
                    绩，并具有多年律师执业经验，其中多名合伙人为广西区
                    内某一法律领域及相关专业的著名专家和学者，分别受聘
                    于自治区政府、政协、法制办、各级仲裁委员会等机构担
                    任社会职务，具有深厚的法律功底和优良的社会背景，精
                    通刑事辩护、建筑房地产、公司企业、金融证券保险、知
                    识产权、民商事、劳动与行政、涉外法务等业务，能为各
                    类客户提供高水准、高质量法律服务。
                    <p />
                    明桂律师事务所坚持优质、高效的服务理念和团队合作的方式，
                    对客户的每一个项目和案件提供细致的法律分析和切实可行的法
                    律建议，积极进取地解决法律问题，多名律师连年荣获广西壮族
                    自治区律师协会优秀律师荣誉称号。
                    <p />
                    明桂律师事务所努力打造“规模化”、“品牌化”、“专业化”律师
                    事务所，设有多个法律业务中心，组建有法律数据分析研究中
                    心、刑事辩护讲师团、民商事及行政法律事务讲师团、金融法律
                    事务部等专业化团队，不仅在各类诉讼领域持续居于全区领先地
                    位，更能在公司事务、风险防控、知识产权、信息技术与互联
                    网、物流，特别是金融、房地产与建筑工程、基础设施与公用事
                    业等方面非诉讼领域为不同需求客户提供全方位、高水准法律支
                    持与服务。
                    <p />
                    明桂律师事务所成立至今，始终奉行“严格守法，尊重事实，质量
                    第一，信誉至上”的宗旨，我们深信，我们有能力利用我们常年积
                    累的各方面的资源，秉承一贯的专业精神，优质、高效、勤勉、
                    尽责地完成各类项目所涉及的法律服务工作。 -->
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>

    <!-- 荣誉证书，资质 -->
    <a-row
      id="info"
      :gutter="16"
      align="top"
      class="row-box-height"
      style="
        position: relative;
        background-color: rgba(0, 0, 0, 0.1);
        width: 100%;
        overflow: hidden;
      "
    >
      <a-col :span="18" offset="3">
        <a-row style="margin-bottom: 20px">
          <div
            style="
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              margin：30px;
              font-size:40px;
              font-weight:border;
              color:black;
            "
          >
            荣誉及资质
          </div>
          <a-col :span="12" :offset="6">
            <div
              style="
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              margin：30px;
              font-size:16px;
            "
            >
              服务好客户是我们的责任
            </div>
          </a-col>
        </a-row>
        <a-row style="margin-bottom: 30px">
          <a-carousel
            autoplay
            autoplaySpeed="80"
            arrows
            dotsClass="slick-dots1"
            data-v-f3f3nw9
          >
            <div
              slot="prevArrow"
              class="custom-slick-arrow"
              style="left: -7%; z-index: 9; color: black"
            >
              <img src="../../public/img/homepicture/首页/组 1070.png" />
            </div>
            <div
              slot="nextArrow"
              class="custom-slick-arrow"
              style="right: -7%; z-index: 9; color: black"
            >
              <img src="../../public/img/homepicture/首页/组 1071.png" />
            </div>
            <div v-for="(item1, index1) in listTemp" :key="index1">
              <div style="width: 100%; height: 100%">
                <a-row :gutter="[16, 16]" align="top" style="">
                  <a-col
                    :span="6"
                    v-for="(item2, index2) in item1"
                    :key="index2"
                    :id="index1 + index2"
                    style="height: 530px"
                  >
                    <div style="display: contents">
                      <a-card
                        :bordered="false"
                        style="
                          width: 100%;
                          max-height: 100%;
                          text-align: left;
                          background-color: transparent;
                        "
                      >
                        <img
                          slot="cover"
                          alt="example"
                          :src="item2.mediaPath"
                          style="
                            object-fit: contain;
                            max-height: 460px;
                            max-width: 350px;
                          "
                        />
                        <a-card-meta>
                          <template slot="description">
                            <div
                              style="
                                width: 100%;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                                font-weight: bolder;
                                color: black;
                              "
                            >
                              {{ item2.dataSetName }}
                            </div>
                          </template>
                        </a-card-meta>
                      </a-card>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-carousel>
        </a-row>
      </a-col>
    </a-row>
    <!-- 荣誉证书，资质 -->

    <!-- 事务所照片 -->
    <a-row style="margin-top: 50px; margin-bottom: 60px">
      <a-col :span="18" offset="3">
        <a-row
          style="
            margin-top: 30px;
            margin-bottom: 10px;
            background-color: #e6e6e6;
          "
        >
          <a-col :span="6" style="">
            <div
              style="
                font-size: 23px;
                font-weight: 400;
                line-height: 40px;
                color: #0a0a0a;
                top: 60px;
                left: 80px;
                position: absolute;
              "
            >
              办公环境
            </div>
            <div
              style="
                top: 110px;
                width: 200px;
                border-bottom: 1px solid black;
                left: 80px;
                position: absolute;
              "
            ></div>
            <div
              style="top: 150px; left: 80px; position: absolute"
              v-html="officeIntro.richText"
            >
              <!-- 这是一段关于事务所环境的描述，具体内容待定 -->
            </div>
          </a-col>
          <a-col :span="18">
            <img
              :src="officePic[0].mediaPath"
              alt=""
              style="height: 100%; float: right"
            />
          </a-col>
        </a-row>
        <a-row :gutter="[9, 9]">
          <a-col :span="12" style="">
            <img
              :src="officePic[1].mediaPath"
              alt=""
              style="height: 100%; width: 100%; float: right"
            />
          </a-col>
          <a-col :span="12">
            <img
              :src="officePic[2].mediaPath"
              alt=""
              style="height: 100%; width: 100%; float: right"
            />
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <!-- 事务所照片 -->
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      qualifications: [
        {
          dataSetName: "顶顶顶顶顶顶顶顶顶",
          mediaPath: "https://minggui.gxxqy.cn//storage/admin/images/66666.png",
        },
      ],
      officePic: [
        {
          dataSetName: "顶顶顶顶顶顶顶顶顶",
          mediaPath: "https://minggui.gxxqy.cn//storage/admin/images/66666.png",
        },
      ],
      intro: "广西明桂律师事务所成立于一九九四年，直属自治区司",
      officeIntro: "zxcvzxcvzvzx",
    };
  },
  mounted() {
    let that = this;

    request({
      url: "/api/GetDataEntityController",
      method: "GET",
      params: null,
    }).then((res) => {
      // console.log("res= ",res)
      console.log(res);
      if (res.data != null) {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].param1 == "qualifications") {
            that.qualifications.push(res.data[i]);
          }
          if (res.data[i].param1 == "officePic") {
            that.officePic.push(res.data[i]);
          }
          if (res.data[i].param1 == "intro") {
            that.intro = res.data[i];
          }
          if (res.data[i].param1 == "officeIntro") {
            that.officeIntro = res.data[i];
          }
        }
      }
      console.log(that.intro);
    });
  },
  computed: {
    // 分页计算，多组
    listTemp: function () {
      var list = this.qualifications;
      // var list = this.qualifications;
      var arrTemp = [];
      var index = 0;
      var sectionCount = 8;
      for (var i = 0; i < list.length; i++) {
        index = parseInt(i / sectionCount);
        if (arrTemp.length <= index) {
          arrTemp.push([]);
        }
        arrTemp[index].push(list[i]);
      }
      return arrTemp;
    },
  },
};
</script>

<style scoped>
.ant-carousel >>> .slick-slider {
  z-index: -5;
}

.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 100%;
  width: 100%;
  line-height: 0;
  background: #364d79;
  overflow: hidden;
  /* border-radius: 15px; */
}

.ant-carousel >>> .slick-dots {
  display: none !important;
  height: auto;
  left: 13%;
  margin: 0px auto;
  text-align: left;
  bottom: 110px;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 50px;
  height: 50px;
  font-size: 50px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.8;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 1;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}

.ant-carousel[data-v-f3f3nw9] >>> .slick-slider {
  z-index: unset;
}
.ant-carousel[data-v-f3f3nw9] >>> .slick-slide {
  z-index: unset !important;
  text-align: center;
  height: 100%;
  width: 100%;
  line-height: 0;
  background: transparent;
  overflow: hidden;
  /* border-radius: 15px; */
}

.ant-carousel[data-v-f3f3nw9] >>> .slick-dots1 {
  height: auto;
  margin: 0px auto;
  text-align: center;
  bottom: -30px;
  left: 0;
}

.ant-carousel[data-v-f3f3nw9] >>> .slick-dots li button {
  display: block;
  width: 32px;
  height: 6px;
  padding: 0;
  color: black !important;
  font-size: 0;
  background: black !important;
  border: 0;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  opacity: 0.1;
  transition: all 0.5s;
}

.ant-carousel[data-v-f3f3nw9] >>> .slick-dots li.slick-active button {
  color: black !important;
  width: 48px;
  background: black !important;
  opacity: 1;
}

.ant-carousel[data-v-f3f3nw9] >>> .custom-slick-arrow {
  width: 50px;
  height: 50px;
  font-size: 50px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel[data-v-f3f3nw9] >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel[data-v-f3f3nw9] >>> .custom-slick-arrow:hover {
  opacity: 1;
}

.ant-carousel[data-v-f3f3nw9] >>> .slick-slide h3 {
  color: #fff;
}
</style>