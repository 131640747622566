<template>
  <div style="background-color: #f4f4f4">
    <a-row id="tdjs">
      <a-col :span="18" offset="3">
        <a-row>
          <a-breadcrumb>
            <a-breadcrumb-item>
              <router-link :to="{ name: '首页' }" v-scroll-to="'#top'">
                <a-icon type="home" />
                <span>首页</span>
              </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item> 团队介绍 </a-breadcrumb-item>
          </a-breadcrumb>
        </a-row>
        <a-row style="margin-top: 50px; margin-bottom: 30px">
          <a-col :span="8" offset="13">
            <h2 style="margin: 30px 0" v-html="webconfig[8].richText">
              <!-- 我们的律师技术娴熟，平易近人。我们的规模使我们的合作伙伴能够对每一件事给予个人关注。 -->
            </h2>
          </a-col>
        </a-row>
        <a-row
          type="flex"
          justify="space-around"
          align="top"
          v-for="(item1, index1) in listTemp2"
          :key="index1"
        >
          <a-col
            :span="4"
            style="text-align: center"
            v-for="(item2, index2) in item1"
            :key="index2"
          >
            <div style="display: inline-flex">
              <router-link
                :to="{ name: '成员页', params: { Lawyer: item2, fromPath } }"
              >
                <a-card :bordered="false" style="background-color: unset">
                  <div>
                    <img
                      slot="cover"
                      style="width: 240px; height: 240px"
                      alt="example"
                      :src="item2.portrait"
                    />
                  </div>
                  <a-card-meta>
                    <template slot="title">
                      <div
                        style="
                          font-weight: 500;
                          float: left;
                          margin-top: 10px;
                          white-space: normal;
                          word-break: break-all;
                          display: -webkit-box;
                          -webkit-box-orient: vertical;
                          -webkit-line-clamp: 3;
                          overflow: hidden;
                        "
                      >
                        {{ item2.name }}
                      </div>
                    </template>
                    <!-- <template slot="description"> {{ item2.content }} </template> -->
                  </a-card-meta>
                </a-card>
              </router-link>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import request from "@/utils/request";
// import moment from "moment";

export default {
  data() {
    return {
      LawyersList: [],
      fromPath: "",
      webconfig: "",
    };
  },
  mounted() {
    this.fromPath = this.$route.params.fromPath;
    var that = this;
    // 获取律师列表
    request({
      url: "/api/GetLawyersController",
      method: "GET",
      params: null,
    }).then((res) => {
      // console.log("res= ",res)
      console.log(res);
      if (res.data != null) {
        that.LawyersList = res.data;

        // var item = new Array();
        // item.title = value.articlesName;
        // item.time = moment(value.created_at).format("YYYY - MM - DD");
        // item.neinId = value.id;
        // item.image_path = value.image_path;
        // item.content = value.content;
        // item.author = value.author;
        // that.articlesList.push(item);
        console.log(that.LawyersList);
      }
    });

    // 请求网站设置
    request({
      url: "/api/GetDataEntityController",
      method: "GET",
      params: null,
    }).then((res) => {
      // console.log("res= ",res)
      // console.log(res);
      if (res.data != null) {
        window.localStorage.setItem("webconfig", JSON.stringify(res.data));
        that.webconfig = res.data;
      }
    });
    // console.log(JSON.parse(window.localStorage.getItem("webconfig")));
  },
  computed: {
    // 团队照片
    listTemp2: function () {
      var list = this.LawyersList; //当前要拆分的数组
      var arrTemp = [];
      var index = 0;
      var sectionCount = 5; //单页或者单行数量
      // 限制6个元素
      for (var i = 0; i < list.length; i++) {
        index = parseInt(i / sectionCount);
        if (arrTemp.length <= index) {
          arrTemp.push([]);
        }
        arrTemp[index].push(list[i]);
      }
      return arrTemp; //分页数组
    },
  },
};
</script>