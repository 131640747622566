<template>
  <div style="background-color: #f4f4f4">
    <a-row id="ywly">
      <a-col :span="18" offset="3">
        <a-row>
          <a-breadcrumb>
            <a-breadcrumb-item>
              <router-link :to="{ name: '首页' }" v-scroll-to="'#top'">
                <a-icon type="home" />
                <span>首页</span>
              </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item> 业务领域 </a-breadcrumb-item>
          </a-breadcrumb>
        </a-row>
        <a-row style="margin-top: 50px; margin-bottom: 30px">
          <h1>明桂律师事务所可以为您提供的帮助：</h1>
        </a-row>
        <a-row :gutter="[20, 30]">
          <a-col :span="8">
            <div style="width: 350px">
              <div>
                <img src="../../public/img/明桂/刑事辩护.svg" alt="" />
              </div>
              <div>刑事辩护</div>
              <div style="height: 10px; border-top: 1px #bca474 solid"></div>
              <div>
                <div v-for="(item, index) in xsbh" :key="index">
                  <img
                    src="../../public/img/明桂/星号.svg"
                    alt=""
                    style="display: inline"
                  />
                  <div style="display: inline">{{ item.title }}</div>
                </div>
              </div>
            </div>
          </a-col>
          <a-col :span="8"
            ><div style="width: 350px">
              <div>
                <img src="../../public/img/明桂/婚姻家庭纠纷.svg" alt="" />
              </div>
              <div>婚姻家庭纠纷</div>
              <div style="height: 10px; border-top: 1px #bca474 solid"></div>
              <div>
                <div v-for="(item, index) in hyjtjf" :key="index">
                  <img
                    src="../../public/img/明桂/星号.svg"
                    alt=""
                    style="display: inline"
                  />
                  <div style="display: inline">{{ item.title }}</div>
                </div>
              </div>
            </div></a-col
          >
          <a-col :span="8"
            ><div style="width: 350px">
              <div>
                <img src="../../public/img/明桂/合同纠纷.svg" alt="" />
              </div>
              <div>合同纠纷</div>
              <div style="height: 10px; border-top: 1px #bca474 solid"></div>
              <div>
                <div v-for="(item, index) in htjf" :key="index">
                  <img
                    src="../../public/img/明桂/星号.svg"
                    alt=""
                    style="display: inline"
                  />
                  <div style="display: inline">{{ item.title }}</div>
                </div>
              </div>
            </div></a-col
          >
        </a-row>
        <a-row :gutter="[20, 30]">
          <a-col :span="8"
            ><div style="width: 350px">
              <div>
                <img src="../../public/img/明桂/代理公司事务.svg" alt="" />
              </div>
              <div>代理公司事务</div>
              <div style="height: 10px; border-top: 1px #bca474 solid"></div>
              <div>
                <div v-for="(item, index) in dlgssw" :key="index">
                  <img
                    src="../../public/img/明桂/星号.svg"
                    alt=""
                    style="display: inline"
                  />
                  <div style="display: inline">{{ item.title }}</div>
                </div>
              </div>
            </div></a-col
          >
          <a-col :span="8"
            ><div style="width: 350px">
              <div>
                <img src="../../public/img/明桂/房产纠纷.svg" alt="" />
              </div>
              <div>房产纠纷</div>
              <div style="height: 10px; border-top: 1px #bca474 solid"></div>
              <div>
                <div v-for="(item, index) in fcjf" :key="index">
                  <img
                    src="../../public/img/明桂/星号.svg"
                    alt=""
                    style="display: inline"
                  />
                  <div style="display: inline">{{ item.title }}</div>
                </div>
              </div>
            </div></a-col
          >
          <a-col :span="8"
            ><div style="width: 350px">
              <div>
                <img src="../../public/img/明桂/资产重组.svg" alt="" />
              </div>
              <div>资产重组</div>
              <div style="height: 10px; border-top: 1px #bca474 solid"></div>
              <div>
                <div v-for="(item, index) in zccz" :key="index">
                  <img
                    src="../../public/img/明桂/星号.svg"
                    alt=""
                    style="display: inline"
                  />
                  <div style="display: inline">{{ item.title }}</div>
                </div>
              </div>
            </div></a-col
          >
        </a-row>
        <a-row :gutter="[20, 30]">
          <a-col :span="8"
            ><div style="width: 350px">
              <div>
                <img src="../../public/img/明桂/法律顾问.svg" alt="" />
              </div>
              <div>法律顾问</div>
              <div style="height: 10px; border-top: 1px #bca474 solid"></div>
              <div>
                <div v-for="(item, index) in flgw" :key="index">
                  <img
                    src="../../public/img/明桂/星号.svg"
                    alt=""
                    style="display: inline"
                  />
                  <div style="display: inline">{{ item.title }}</div>
                </div>
              </div>
            </div></a-col
          >
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
// 刑事辩护
const xsbh = [
  { title: "死刑复核" },
  { title: "律师会见" },
  { title: "取保候审" },
  { title: "出庭辩护" },
  { title: "刑事报案" },
  { title: "再审申诉" },
  { title: "专家论证" },
  { title: "法律咨询" },
  { title: "附带民事" },
];
// 婚姻家庭纠纷
const hyjtjf = [
  { title: "婚约同居纠纷" },
  { title: "结婚纠纷" },
  { title: "实质关系" },
  { title: "离婚纠纷" },
  { title: "离婚后财产纠纷" },
  { title: "离婚后损害赔偿纠纷" },
  { title: "共同财产分割纠纷" },
  { title: "子女抚养纠纷" },
];
// 合同纠纷
const htjf = [
  { title: "有无效合同纠纷" },
  { title: "口头和书面合同纠纷" },
  { title: "国内和涉外合同纠纷" },
  { title: "有无名合同纠纷" },
  { title: "标准和非标准合同纠纷" },
];
// 代理公司事务
const dlgssw = [
  { title: "解答法律咨询、依法提供建议或者出具法律意见书" },
  {
    title:
      "协助草拟、制订、审查或者修改合同、章程、规章等公司日常经营管理活动中发生或需要的合同、协议或文件",
  },
  { title: "应公司要求，参与磋商、谈判，从法律层面上进行分析、论证" },
  { title: "受公司委托，签署、送达或接受法律事务方面的文件" },
  {
    title:
      "应公司要求，就公司已经面临或者可能发生的纠纷，进行法律论证，提出解决方案，出具律师函，发表律师意见，或者参与非诉讼事务谈判、协调、调解",
  },
  { title: "应公司要求，讲授法律实务知识" },
];
// 房产纠纷
const fcjf = [
  { title: "房地产开发合同纠纷" },
  { title: "建设工程合同纠纷" },
  { title: "房地产服务合同纠纷" },
  { title: "房地产租赁合同纠纷" },
  { title: "物业管理纠纷" },
  { title: "他项权利侵权纠纷" },
  { title: "土地所有权和使用的争议纠纷" },
];
// 资产重组
const zccz = [
  { title: "收购兼并" },
  {
    title:
      "股权转让，包括非流通股的划拨、有偿转让和拍卖等，以及流通股的二级市场购并",
  },
  {
    title:
      "资产剥离或所拥有股权出售，是指上市公司将企业资产或所拥有股权从企业中分离、出售的行为",
  },
  {
    title:
      "资产置换，包括上市公司资产(含股权、债权等)与公司外部资产或股权互换的活动",
  },
  { title: "退休金或保险伤残索赔" },
];
// 法律顾问
const flgw = [
  { title: "解答法律咨询、依法提供建议或者出具法律意见书" },
  { title: "协助草拟、制订、审查或者修改合同、章程等法律文书" },
  { title: "应客户要求，参与磋商、谈判，进行法律分析、论证" },
  { title: "受客户委托，签署、送达或者接受法律文件" },
  {
    title:
      "应要求，就客户已经、面临或者可能发生的纠纷，进行法律论证，提出解决方案，出具交涉函件，发表法律意见，或者参与非诉讼谈判、协调、调解",
  },
  { title: "应客户要求，讲授法律实务知识" },
  { title: "应客户要求监测与客户相关的法律法规的发布和修改" },
  { title: "代理参加诉讼、调解或仲裁活动" },
  { title: "协助企业客户建立法律服务机构，进行法制培训和法制宣传" },
];

export default {
  data() {
    return {
      xsbh,
      hyjtjf,
      htjf,
      dlgssw,
      fcjf,
      zccz,
      flgw,
    };
  },
};
</script>