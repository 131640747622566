<template>
  <div style="background-color: #f4f4f4">
    <a-row>
      <a-col :span="18" offset="3">
        <a-row>
          <a-breadcrumb>
            <a-breadcrumb-item>
              <router-link :to="{ name: '首页' }" v-scroll-to="'#top'">
                <a-icon type="home" />
                <span>首页</span>
              </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item> 团队介绍 </a-breadcrumb-item>
          </a-breadcrumb>
        </a-row>
      </a-col>
    </a-row>
    <a-row
      style="margin-top: 50px; margin-bottom: 30px; background-color: #efeded"
    >
      <a-col :span="18" offset="3">
        <a-row style="margin-top: 30px; margin-bottom: 30px">
          <a-col :span="10">
            <img
              :src="Lawyer.portrait"
              alt=""
              style="width: 35em; height: 35em; object-fit: contain"
            />
          </a-col>
          <a-col :span="14">
            <div style="color: #363636">
              <div
                style="
                  font-size: 40px;
                  font-weight: 500;
                  margin: 1em 0;
                  word-break: break-all;
                "
              >
                {{ Lawyer.name }}
                <!-- 律政先锋张伟 -->
              </div>
              <div
                style="font-size: 18px; line-height: 31px"
                v-html="Lawyer.position"
              >
                <!-- 张伟律师，西南政法大学法学学士、广西师范大学法哲学研究生学历，广西律协刑辩委主任南宁仲裁委员会仲裁员，明桂律师事务所高级合伙人、执行主任、负责人 -->
              </div>
              <div style="margin: 50px 0">
                <router-link :to="{ name: '关于我们' }" v-scroll-to="'#gywm'">
                  <div
                    style="
                      background-color: #bca474;
                      color: white;
                      padding: 10px;
                      margin: 30px 0;
                      cursor: pointer;
                      width: 128px;
                      text-align: center;
                      display: inline;
                    "
                  >
                    关于我们
                  </div>
                </router-link>
                <div style="display: inline; margin-left: 5em">
                  电话： {{ Lawyer.phone }}
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>

    <!-- 执业证号，人物简介 -->
    <a-row style="margin-top: 50px; margin-bottom: 30px">
      <a-col :span="18" offset="3">
        <a-row style="margin-top: 30px; margin-bottom: 30px" :gutter="20">
          <a-col :span="10">
            <div
              style="
                font-size: 18px;
                font-weight: 400;
                line-height: 31px;
                color: #bca474;
              "
            >
              执业证号
            </div>
            <div
              style="
                font-weight: 400;
                line-height: 34px;
                color: #363636;
                font-size: 18px;
              "
            >
              {{ Lawyer.lawyer_license }}
              <!-- 14501199****654541 -->
            </div>
          </a-col>
          <a-col :span="14">
            <div
              style="
                font-size: 18px;
                font-weight: 400;
                line-height: 31px;
                color: #bca474;
              "
            >
              人物简介
            </div>
            <div
              style="
                font-size: 20px;
                font-weight: 400;
                line-height: 34px;
                word-break: break-all;
                color: #363636;
                width: 100%;
              "
              v-html="Lawyer.profile"
            >
              <!-- <p>
                张伟律师是明桂律师事务所的重要合伙人擅长刑事辩护及建筑房地产、公司企业、金融证券业务。
              </p>
              <p>
                张伟律师自1986年以来一直专门从事人身伤害律师。他已成功为数千名受伤人员追回赔偿，并在许多复杂和突出的案件中提供代理。他致力于与客户合作，以​​及时且具有成本效益的方式获得公平的报酬。他的大部分工作是根据满意客户的推荐或从认可他的专业知识的其他法律专业人士的推荐获得的。
              </p> -->
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <!-- 执业证号，人物简介 -->

    <!-- 擅长领域，个人荣誉 -->
    <a-row style="margin-top: 50px; margin-bottom: 60px">
      <a-col :span="18" offset="3">
        <a-row style="margin-top: 30px; margin-bottom: 30px" :gutter="20">
          <a-col :span="10">
            <div
              style="
                font-size: 18px;
                font-weight: 400;
                line-height: 31px;
                color: #bca474;
              "
            >
              擅长领域
            </div>
            <div
              style="
                font-weight: 400;
                line-height: 34px;
                color: #363636;
                font-size: 18px;
                word-break: break-all;
              "
              v-html="Lawyer.skill"
            >
              <!-- 刑事辩护及建筑房地产、公司企业、金融证券业务。 -->
            </div>
          </a-col>
          <a-col :span="14">
            <div
              style="
                font-size: 18px;
                font-weight: 400;
                line-height: 31px;
                color: #bca474;
              "
            >
              个人荣誉
            </div>
            <div
              style="
                font-size: 20px;
                font-weight: 400;
                line-height: 34px;
                color: #363636;
                word-break: break-all;
              "
              v-html="Lawyer.honors"
            >
              <!-- <p>2021年荣获《律政先锋》荣誉证书</p> -->
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <!-- 擅长领域，个人荣誉 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      Lawyer: [],
    };
  },
  mounted() {
    console.log(this.$route.params.Lawyer);
    if (this.$route.params.Lawyer == null) {
      this.$router.push({ name: "团队介绍" });
    }
    this.Lawyer = this.$route.params.Lawyer;
  },
  computed: {
    // 团队照片
    listTemp2: function () {
      var list = this.ourTeam; //当前要拆分的数组
      var arrTemp = [];
      var index = 0;
      var sectionCount = 5; //单页或者单行数量
      // 限制6个元素
      for (var i = 0; i < list.length; i++) {
        index = parseInt(i / sectionCount);
        if (arrTemp.length <= index) {
          arrTemp.push([]);
        }
        arrTemp[index].push(list[i]);
      }
      return arrTemp; //分页数组
    },
  },
};
</script>