<template>
  <div style="overflow: hidden">
    <div style="display: none">202106161616</div>
    <a-row :gutter="16" align="top" style="height: 100%">
      <a-col :span="6"></a-col>
      <a-col
        :span="12"
        class="row-box row-box-height"
        style="background-color: white"
      >
        <a-breadcrumb>
          <a-breadcrumb-item>
            <router-link :to="{ name: '首页' }" v-scroll-to="'#top'">
              <a-icon type="home" />
              <span>首页</span>
            </router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item> 经典案例 </a-breadcrumb-item>
        </a-breadcrumb>
        <div
          style="
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            height: 100%;
          "
        >
          <div
            style="
              font-size: 25px;
              line-height: 30px;
              text-align: center;
              padding: 20px;
              color: #222;
              font-weight: 700;
              padding-left: 15px;
              padding-right: 15px;
            "
          >
            <div
              style="
                font-size: 25px;
                line-height: 30px;
                text-align: center;
                padding: 20px;
                color: #222;
                font-weight: 700;
              "
            >
              {{ articleTitle }}
            </div>

            <div style="flex-direction: row">
              <div style="border-bottom: 1px solid #bca474; opacity: 0.6"></div>
            </div>
            <div
              style="
                color: #9a9a9a;
                font-size: 13px;
                margin-left: 5px;
                margin-right: 5px;
                text-align: left;
                font-weight: 300;
              "
            >
              发表时间：{{ articleCreatedate }} 来源：{{ articleAuthor }}
            </div>
          </div>
          <div
            style="margin: 0; padding: 0;h max-height: 75%; overflow-y: auto"
          >
            <div
              style="
                height: 100%;
                max-height: 580px;
                width: 90%;
                padding: 30px;
                margin-left: auto;
                margin-right: auto;
                text-indent: 2em;
                text-align: justify;
              "
            >
              <div v-for="(item, index) in article" :key="index">
                <div v-if="!item.absolutepath" v-html="item.necoContent"></div>
                <img
                  style="width: 100%; text-indent: unset"
                  v-if="item.necoTexttype == 1"
                  :src="item.absolutepath"
                />
                <!-- PDFJS -->
                <div v-if="item.necoTexttype == 2" v-show="!finish">
                  正在读取……
                </div>
                <div
                  class="pdfh5"
                  style="width: 100%"
                  v-if="item.necoTexttype == 2"
                  v-show="finish"
                >
                  <div :id="'pdf' + index"></div>
                </div>
                <!-- PDFJS -->
              </div>

              <div style="height: 80px"></div>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="6"></a-col>
    </a-row>
  </div>
</template>

<script>
import request from "@/utils/request";
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css";
export default {
  data() {
    return {
      article: [
        {
          necoContent:
            "在当晚的讲话中，习近平主席表示，中方欢迎美方重返多边气候治理进程，期待同包括美方在内的国际社会一道，共同为推进全球环境治理而努力。重要基石：坚持共同但有区别的责任原则共同但有区别的责任原则，是全球气候治理的基石。习近平主席曾经打过一个比方：就像一场赛车一样，有的车已经跑了很远，有的车刚刚出发，这个时候用统一尺度来限制车速是不适当的，也是不公平的。他由此引申说，坚持共同但有区别的责任等原则，不是说发展中国家就不要为全球应对气候变化作出贡献了，而是说要符合发展中国家能力和要求",
        },
      ],
      articleTitle: "测试标题",
      articleCreatedate: "2021年06月30日",
      articleAuthor: "测试作者",
      fromPath: "",
      pdfh5: null,
      finish: false,
    };
  },
  mounted() {
    console.log(this.$route.params.article);
    if (this.$route.params.article == null) {
      this.$router.go(-1);
    }
    this.fromPath = this.$route.params.fromPath;
    this.articleTitle = this.$route.params.article.title;
    this.articleCreatedate = this.$route.params.article.time;
    this.article[0].necoContent = this.$route.params.article.content;
    this.articleAuthor = this.$route.params.article.author;
    // var that = this;
    //   request({
    //     url: "/newsInfo/getNewsInfoDetails",
    //     method: "GET",
    //     params: {
    //       id: this.$route.params.article,
    //     },
    //   }).then((res) => {
    //     // console.log("res= ",res)
    //     console.log(res.data);
    //     that.article = res.data.data.newsContent;
    //     that.articleTitle = res.data.data.newsInfo.neinNewsname;
    //     that.articleCreatedate = res.data.data.newsInfo.neinCreatedate;
    //     that.articleAuthor = res.data.data.newsInfo.neinAuthor;
    //     //   res.data.data.forEach(function (value, index) {
    //     //     console.log(index);
    //     //     if (index == 4) {
    //     //       console.log(value);
    //     //       //"home_scroll_top"  图片，

    //     //       value.children.forEach(function (value2, index2) {
    //     //         console.log(value2, index2);
    //     //         var item = new Object();
    //     //         item.title = value2.neinNewsname;
    //     //         item.time = value2.neinStartdate;
    //     //         that.listData.push(item);
    //     //       });
    //     //     }
    //     //   });
    //     this.onloadPdf(res.data.data.newsContent);
    //   });
  },
  methods: {
    onloadPdf(article) {
      let that = this;
      article.forEach((element, index) => {
        if (element.necoTexttype == 2) {
          // Pdfh5使用
          request({
            url: element.absolutepath,
            method: "GET",
            // params: {
            //   id: this.$route.params.articleid,
            // },
            responseType: "arraybuffer",
          }).then((res) => {
            this.pdfh5 = new Pdfh5("#pdf" + index, {
              data: res.data,
            });
            // 监听完成事件;
            this.pdfh5.on("complete", function (status, msg, time) {
              that.finish = true;
              console.log(
                "状态：" +
                  status +
                  "，信息：" +
                  msg +
                  "，耗时：" +
                  time +
                  "毫秒，总页数：" +
                  this.totalNum
              );
            });
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.pdfh5 >>> img {
  width: 100%;
}

.pdfh5 >>> .viewerContainer {
  overflow: unset !important;
}
</style>