<template>
  <div>
    <a-list
      item-layout="vertical"
      size="small"
      :pagination="pagination"
      :data-source="articlesList"
    >
      <a-list-item
        slot="renderItem"
        key="item.title"
        slot-scope="item"
        style="display: flex"
      >
        <div style="flex: 1 0; font-size: 20px">
          <router-link
            :to="{
              name: '文章页',
              params: { article: item, fromPath },
            }"
          >
            <a-row>
              <a-col :span="10">
                <img
                  :src="item.image_path"
                  alt=""
                  style="height: 10em; width: 15em; object-fit: contain"
                />
              </a-col>
              <a-col :span="14">
                <div
                  style="
                    margin-top: 0.5em;
                    font-weight: bolder;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                    word-break: break-all;
                    color: black;
                  "
                >
                  {{ item.title }}
                </div>
              </a-col>
            </a-row>
          </router-link>
          <a-row>
            <a-col :span="12" style="color: rgba(0, 0, 0, 0.3)">{{
              item.time
            }}</a-col>
          </a-row>

          <a-row style="margin: 1em 0">
            <a-col :span="24">
              <div
                style="
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 3;
                  overflow: hidden;
                  color: black;
                "
              >
                {{ filtersText(item.content) }}
              </div>
            </a-col>
          </a-row>
          <!-- <div style="color: #bca474">阅读更多...</div> -->
        </div>
        <!-- <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: left;
            color: #bca474;
          "
        >
          {{ item.time }}
        </div> -->
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
// 测试数据

// const listData = [
//   {
//     title: "Ant Design Title 1",
//   },
//   {
//     title: "Ant Design Title 2",
//   },
//   {
//     title: "Ant Design Title 3",
//   },
//   {
//     title: "Ant Design Title 4",
//   },
//   {
//     title: "Ant Design Title 4",
//   },
//   {
//     title: "Ant Design Title 4",
//   },
//   {
//     title: "Ant Design Title 4",
//   },
//   {
//     title: "Ant Design Title 4",
//   },
//   {
//     title: "Ant Design Title 4",
//   },
//   {
//     title: "Ant Design Title 4",
//   },
//   {
//     title: "Ant Design Title 4",
//   },
// ];

import request from "@/utils/request";
import moment from "moment";

export default {
  name: "news",
  props: {
    interfaceUrl: String,
    pageSize: {
      default: 6,
    },
    categoryId: String,
  },
  data() {
    return {
      articlesList: [],
      pagination: {
        onChange: (page) => {
          console.log(page);
        },
        pageSize: this.pageSize,
        // showQuickJumper: true,
      },
      fromPath: "",
    };
  },
  mounted() {
    console.log(this.interfaceUrl);
    this.fromPath = this.$route.name;
    var that = this;
    request({
      url: this.interfaceUrl,
      method: "GET",
      params: { id: this.categoryId },
    }).then((res) => {
      // console.log("res= ",res)
      console.log(res);
      if (res.data != null) {
        res.data.forEach(function (value) {
          var item = new Array();
          item.title = value.articlesName;
          item.time = moment(value.created_at).format("YYYY - MM - DD");
          item.neinId = value.id;
          item.image_path = value.image_path;
          item.content = value.content;
          item.author = value.author;
          that.articlesList.push(item);
        });
      }
    });
  },
  methods: {
    // 文本内容html标签过滤
    filtersText(val) {
      // 要判断一下,如果是空就返回空字符串,不然会报错
      if (val != null && val != "") {
        var reg = /[\u4e00-\u9fa5]/g;
        var names = val.match(reg);
        val = names.join("");
        return val.substr(0, 150); //获取富文本文字内容的前50个字符
      } else return "";
    },
  },
};
</script>


<style>
.ant-spin-nested-loading {
  border-radius: 10px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.3);
}

.ant-list-something-after-last-item
  .ant-spin-container
  > .ant-list-items
  > .ant-list-item:last-child {
  border-bottom: 1px solid #67676d;
}

.ant-list-split .ant-list-item {
  border-bottom: 1px solid #67676d;
}

.ant-pagination-item-link {
  background: rgba(255, 255, 255, 0.3) !important;
}

.ant-pagination-item-link:focus,
.ant-pagination-item-link:hover {
  color: #bca474 !important;
  border-color: #bca474 !important;
}

.ant-pagination-item-link a {
  color: #bca474 !important;
}

.ant-pagination-item {
  background: rgba(255, 255, 255, 0.3);
}

.ant-pagination-item-active {
  border-color: #bca474 !important;
  background: rgba(255, 255, 255, 0.3);
}

.ant-pagination-item-active a {
  color: #bca474 !important;
}

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #bca474 !important;
}

.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #bca474 !important;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #bca474 !important;
  background: rgba(255, 255, 255, 0.3);
}

.ant-list a:active,
.ant-list a:hover {
  text-decoration: none;
  outline: 0;
  color: black !important;
}

.ant-list-pagination {
  text-align: center !important;
}
.ant-spin-nested-loading {
  background: none;
}
.ant-pagination-options-quick-jumper {
  color: #bca474;
}

.ant-pagination-options-quick-jumper input {
  color: rgba(246, 222, 141, 0.3);
}
</style>
